import React, { useState, useEffect, useRef } from "react";

import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Ripple } from "primereact/ripple";
import { classNames } from "primereact/utils";
import { FilterMatchMode } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { useForm, Controller } from "react-hook-form";
import display_settings from "assets/svgs/display_settings.svg";

import {
  BsPlusLg,
  BsPaperclip,
  BsFillPencilFill,
  BsTrashFill,
  BsBoxArrowRight,
  BsCheckCircle,
  BsXCircle,
  BsCheckCircleFill,
  BsXCircleFill,
  BsGridFill,
} from "react-icons/bs";
import { FaDownload, FaCog, FaChevronDown, FaUserCheck, FaEye} from "react-icons/fa";
import { MdTableRows } from "react-icons/md";
import {
  IoIosRemoveCircleOutline,
  IoIosCopy,
  IoIosFunnel,
  IoMdOptions,
} from "react-icons/io";

import PopOver from "components/common/PopOver/PopOver";
import DisplaySetting from "components/common/DisplaySetting/DisplaySetting";

interface ColProp {
  field: string;
  header: string;
  editor?: any;
  style?: any;
  sortable: boolean;
}

type Cols = ColProp[];

type Rows = any[];

type CountryListItem = {
  id: string | number;
  countryName: string;
  imgSrc: any;
  altText?: string;
  active: boolean;
};

type SettingListItem = {
  id: string | number;
  name: string;
  onClick: () => void;
};

type LookupItem = {
  code: string | number;
  name: string;
};

interface ITableComponent {
  rows: Rows;
  cols: Cols;
  title: string;
  paginator?: boolean;
  showroweditor?: boolean;
  showCheckbox?: boolean;
  searchText?: string;
  dataKeyId?: string;
  pageDetails?: any;
  pageIndex?: number;
  onAddClick?: () => void;
  onEditClick?: () => void;
  onUploadXML?: () => void;
  onAttachmentClick?: (e: React.SyntheticEvent) => void;
  onDeleteClick?: (e: React.SyntheticEvent) => void;
  getSelectedRowData?: (data: any) => void;
  onDownloadClick?: (e: React.SyntheticEvent) => void;
  onSaveRowEdit?: (data: any) => void;
  onRowEditValidation?: (data: any) => boolean;
  onPageChanged?: (data: any) => void;
  onRowEditClick?: (data: any) => boolean;
  onSetAsActiveSiteClick?: () => void;
  showAttachIcon?: boolean;
  showCountries?: boolean;
  countryData?: Array<CountryListItem>;
  getSelectedCountry?: (item: CountryListItem) => void;
  showAddIcon?: boolean;
  disableAddIcon?: boolean;
  showEditIcon?: boolean;
  showDeleteIcon?: boolean;
  showHamIcon?: boolean;
  showOrderIcon?: boolean;
  onHamClick?: () => void;
  showDownloadIcon?: boolean;
  showSettingIcon?: boolean;
  showViewDraftReports?: boolean;

  showViewFinalReport?: boolean;
  showDownoadDraftReport?: boolean;
  showDownloadFinalReport?: boolean;

  showTextReport?: boolean;
  settingListItems?: Array<SettingListItem>;
  showDeleteDownArrow?: boolean;
  setAsActiveSiteIcon?: boolean;
  showDetailsIcon?: boolean;
  onDetailsClick?: () => void;
  showCloseIcon?: boolean;
  onCloseClick?: () => void;
  showCompleteIcon?: boolean;
  onCompleteClick?: () => void;
  showFontAdjust?: boolean;
  onFontChange?: (value: any) => void;
  showCustomHeader?: boolean;
  CustomHeaderCmp?: any;
  showExpandedRows?: boolean;
  showEquipmentDropdown?: boolean;
  showSystemDropdown?: boolean;
  showAttributeDropdown?: boolean;
  showCommonAttributeDropDown?: boolean;
  onHeaderAttributeNameChange?: (value: any) => void;
  searchboxfield?: boolean;
  showCustomSearchCmp?: boolean;
  CustomSearchCmp?: any;
  showFilterIcon?: boolean;
  onFilterClick?: () => void;
  showOptionsIcon?: boolean;
  onOptionsClick?: () => void;
  showCopyIcon?: boolean;
  onCopyClick?: () => void;
  attributeLookup?: Array<LookupItem>;
  equipmentTypeLookup?: Array<LookupItem>;
  system?: Array<LookupItem>;
  onHeaderDropdownChange?: (value: any) => void;
  onGlobalSearch?: (value: any) => void;
  onSortData?: (value: any) => void;
  isLazyLoading?: boolean;
  defaultSelectedRows?: any;
  showActions?: boolean;
  isTemplateMode?: boolean | undefined;
  allowRowEditing?:boolean;
  showCountryDropdown?: boolean;
  countryLookup?: Array<LookupItem>;
  onCountryDropdownChange?: (value: any) => void;
  onGridDataSearch?: (value: any) => void;
  dataFromUserTable?: boolean;
  onUserSearchApplied?: boolean;
  localSearchFirstItem?: (value: any) => void;
  templateFromParent?: (rowData: any, options: any) => JSX.Element;
  isTemplateFromParent?: boolean;
  showViewIcon?: boolean;
  showDislaySettingsIcon?: boolean;
  onViewClick?: (value: any) => void;
}

export const TableComponent: React.FC<ITableComponent> = ({
  rows = [],
  cols = [],
  title = "",
  paginator = false,
  searchText = "",
  dataKeyId = "",
  pageDetails = {},
  pageIndex = 1,
  onAddClick = () => {},
  onEditClick = () => {},
  onDeleteClick = () => {},
  onAttachmentClick = () => {},
  getSelectedRowData = () => {},
  onDownloadClick = () => {},
  onSaveRowEdit = () => {},
  onRowEditValidation = () => true,
  onPageChanged = () => {},
  onRowEditClick = () => true,
  onSetAsActiveSiteClick = () => {},
  showAttachIcon = false,
  showCountries = false,
  countryData = [],
  getSelectedCountry = () => {},
  showAddIcon = true,
  disableAddIcon = false,
  showEditIcon = true,
  showDeleteIcon = true,
  showroweditor = true,
  showCheckbox = false,
  showHamIcon = false,
  showOrderIcon = false,
  onHamClick = () => {},
  showDownloadIcon = true,
  showSettingIcon = true,
  showViewDraftReports = false,
  showViewFinalReport = false,
  showDownoadDraftReport = false,
  showDownloadFinalReport = false,
  showTextReport = false,
  settingListItems = [],
  showDeleteDownArrow = false,
  setAsActiveSiteIcon = false,
  showDetailsIcon = false,
  onDetailsClick = () => {},
  showCloseIcon = false,
  onCloseClick = () => {},
  showCompleteIcon = false,
  onCompleteClick = () => {},
  showFontAdjust = false,
  onFontChange = () => {},
  showCustomHeader = false,
  CustomHeaderCmp = null,
  showExpandedRows = false,
  showEquipmentDropdown = false,
  showSystemDropdown = false,
  showAttributeDropdown = false,
  showCommonAttributeDropDown = false,
  searchboxfield = true,
  onHeaderAttributeNameChange = () => {},
  showCustomSearchCmp = false,
  CustomSearchCmp = null,
  showFilterIcon = false,
  onFilterClick = () => {},
  showOptionsIcon = false,
  onOptionsClick = () => {},
  showCopyIcon = false,
  onCopyClick = () => {},
  attributeLookup = [],
  equipmentTypeLookup = [],
  system = [],
  onHeaderDropdownChange = () => {},
  onGlobalSearch = () => {},
  onSortData = () => {},
  isLazyLoading = true,
  defaultSelectedRows = [],
  showActions = true,
  isTemplateMode = false,
  allowRowEditing = true,
  showCountryDropdown = false,
  countryLookup = [],
  onCountryDropdownChange = () => {},
  onGridDataSearch = () => {},
  dataFromUserTable = false,
  onUserSearchApplied = false,
  localSearchFirstItem = () => {},
  isTemplateFromParent = false,
  templateFromParent,
  showViewIcon = false,
  showDislaySettingsIcon = false,
  onViewClick = () => {},
}) => {
  const settingRef = React.createRef<OverlayPanel>();

  const [rowData, setRowData] = useState<Array<any>>([]);
  const [tableColumns, setTableColumns] = useState<Array<any>>(cols);
  const [totalRows, setTotalRows] = useState(100);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortField, setSortField] = useState<any>();
  const [sortOrder, setSortOrder] = useState(null);
  const [firstPage, setFirstPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [disabledIcon, setDisabledIcon] = useState(false);
  const [pageInputTooltip, setPageInputTooltip] = useState(
    "Press 'Enter' key to go to this page."
  );
  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [countryList, setCountryList] = useState<Array<any>>(countryData);
  const [editingRows, setEditingRows] = useState({});
  const [showDisplaySetting, setShowDisplaySetting] = useState<boolean>(false);

  const [expandedRows, setExpandedRows] = useState<any>(null);
  const initialFlexesRef = useRef<{ [key: string]: string }>({});

  useEffect(() => {
    if (rows?.length === 0) {
      setDisabledIcon(true)
    }
    else {
      setDisabledIcon(false)
    }
  }, [rows]);

  useEffect(() => {
    if (cols) {       
      const updatedColumns = cols.map((col, index) => { 
      const flexValue=col.style.flex;          
          return {
            ...col,
            style: {
                flex: flexValue,
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                overflow: 'hidden'
            }
          };
      });    
      setTableColumns(updatedColumns);
    }
  }, [cols, rows]);

useEffect(()=>{
if(!allowRowEditing)
{
 setEditingRows({})
}
},[allowRowEditing])

  useEffect(() => {
    setRowData(rows)
    setPageInputTooltip("Press 'Enter' key to go to this page.")
    setCurrentPage(pageIndex + 1)
    setFirstPage(pageIndex * pageDetails?.currentLength)
    let lastPageIndex = Math.ceil(pageDetails?.totalCount / pageDetails?.currentLength)
    setLastPage(lastPageIndex)
    setTotalRecords(pageDetails?.totalCount)
    setTotalRows(pageDetails?.pageCount);
  }, [pageDetails, rows, pageIndex]);

  useEffect(() => {
    if (onUserSearchApplied) {
      filters["global"].value = null;
      filtersMap['filters'].callback(filters);
    }
  }, [onUserSearchApplied]);


  

  const onCustomPage = (event) => {
    setFirstPage(event.first);
    setTotalRows(event.rows);
    setCurrentPage(event.page + 1);
    onPageChanged && onPageChanged(event.page);
  };

  const onSort = (event) => {
    setFirstPage(event.first);
    setTotalRows(event.rows);
    setSortField(event.sortField);
    setSortOrder(event.sortOrder);
    onSortData && onSortData(event);
  }

  const onDropdownChanges = () => {
    filters["global"].value = null;
    filtersMap['filters'].callback(filters);
  };

  const paginatorTemplate = {
    layout:
      "PrevPageLink NextPageLink FirstPageLink PageLinks LastPageLink RowsPerPageDropdown CurrentPageReport JumpToPageInput",
    FirstPageLink: (options) => {
      if (pageIndex > 2 && lastPage > 5) {
      return (
        <button
          type="button"
          className={`${options.className} mr-3`}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="action-btn">1</span>
          <Ripple />
        </button>
      );
      }
    },
    PrevPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="action-btn">Previous</span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          className={`${options.className} mr-3`}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="action-btn">Next</span>
          <Ripple />
        </button>
      );
    },
    LastPageLink: (options) => {
      if (pageIndex < (lastPage-3) && lastPage > 5) {
      return (
        <button
          type="button"
          className={`${options.className} mr-3`}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="action-btn">{lastPage}</span>
          <Ripple />
        </button>
      );
      }
    },
    PageLinks: (options) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { "p-disabled": true });

        return (
          <span className={`${className} mr-3`} style={{ userSelect: "none" }}>
            ...
          </span>
        );
      }

      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
        >
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
    JumpToPageInput: (options) => {
      return null;
    },
    RowsPerPageDropdown: (options) => {
      return null;
    },
    CurrentPageReport: (options) => {
      return (
        <span
          className="current-page mr-3"
          style={{ color: "var(--text-color)", userSelect: "none" }}
        >
          Go to{" "}
          <InputNumber
            size={2}
            className="ml-1"
            value={currentPage}
            tooltip={pageInputTooltip}
            tooltipOptions={{ position: "left" }}
            onKeyDown={(e) => onPageInputKeyDown(e, options)}
            onChange={onPageInputChange}
          />
        </span>
      );
    },
  };

  const onPageInputKeyDown = (event, options) => {
    if (event.key === "Enter") {
      const page = parseInt(currentPage as any as string);
      if (page < 1 || page > options.totalPages) {
        setPageInputTooltip(
          `Enter a value less than or equal to ${options.totalPages}.`
        );
      } else {
        const first = currentPage ? options.rows * (page - 1) : 0;
        onPageChanged && onPageChanged(page - 1);
        setFirstPage(first);
        setPageInputTooltip("Press 'Enter' key to go to this page.");
      }
    }
  };

  const onPageInputChange = (event) => {
    setCurrentPage(event.value === null ? 0 : event.value);
  };

  const filtersMap = {
    filters: { value: filters, callback: setFilters },
  };

  const onGlobalFilterChange = (event, filtersKey) => {
    const value = event.target.value;
    let filters = { ...filtersMap[filtersKey].value };
    filters["global"].value = value;
    filtersMap[filtersKey].callback(filters);
    if (isLazyLoading)
    onGlobalSearch && onGlobalSearch(value)
    else
    onGridDataSearch(value)
  }; 

  const onRowDataChanges = (data) => {
    if(!isLazyLoading) {
      if(data.length < 1) {
        setDisabledIcon(true);
        setSelectedRows([]);
        localSearchFirstItem([]);
      } else {
        setDisabledIcon(false);
        setSelectedRows([data[0]]);
        localSearchFirstItem([data[0]]);
      }
    }
  };

  const handleCountryClick = (selectedItem) => {
    const modifiedList = countryList.map((item) => {
      if (item?.countryName === selectedItem?.countryName) {
        item.active = true;
      } else {
        item.active = false;
      }
      return item;
    });
    const activeListItem = modifiedList.find((item) => item?.active);
    setCountryList(modifiedList);
    getSelectedCountry(activeListItem);
  };

  const handleFontChange = (e: any) => {
    e.preventDefault();
    if (Array.from(e?.target?.classList).includes("text")) {
      const classNamesArr = Array.from(e?.target?.classList);
      onFontChange(classNamesArr[classNamesArr.length - 1]);
    }
  };

  const renderHeader = (filtersKey) => {
    const filters = filtersMap[`${filtersKey}`].value;
    const value = filters["global"] ? filters["global"].value : "";

    return (
      <div
        className={classNames("d-flex", {
          "align-items-center justify-content-between": !showCustomSearchCmp,
        })}
      >
        {showCustomSearchCmp ? (
          <CustomSearchCmp />
        ) : (
          <div className="search-section d-flex align-items-center">
            {searchboxfield ? (
              <span className="p-input-icon-left table-search mr-4">
                <InputText
                  type="search"
                  value={value || ""}
                  onChange={(e) => onGlobalFilterChange(e, filtersKey)}
                  placeholder={searchText}
                />
                {!value && <i className="pi pi-search" title="Search Button"/>}
              </span>
            ) : null}
            {showCountries &&
              countryList.map((item: CountryListItem) => (
                <div
                  className={classNames(
                    "country-item d-flex align-items-center px-2 mx-2",
                    { active: item?.active }
                  )}
                  key={item?.id}
                  role="button"
                  onClick={() => handleCountryClick(item)}
                >
                  <img src={item?.imgSrc} alt={item?.altText} />
                  <span className="pl-2">{item?.countryName}</span>
                </div>
              ))}
            <div className="attributes-dropdowns d-flex align-items-center">
               {showSystemDropdown &&
                systemHeaderDropdowns(
                  system,
                  "System"
                )}
              {showEquipmentDropdown &&
                renderHeaderDropdowns(
                  equipmentTypeLookup,
                  "Equipment Type Name"
                )}
              {showAttributeDropdown &&
                renderAttributeHeaderDropdowns(
                  attributeLookup,
                  "Attribute Name"
                )}
              {showCommonAttributeDropDown &&
                renderCommonAttributeDropdowns(
                  attributeLookup,
                  "Common Attribute Name"
                )}
                {showCountryDropdown &&
                renderCountryDropdown(
                  countryLookup,
                  "Country"
                )}
            </div>
          </div>
        )}

        <div
          className={classNames("table-config", {
            "custom-align": showCustomSearchCmp,
          })}
        >
          {showFontAdjust && (
            <div
              className="font-adjust-section"
              onClick={(e) => handleFontChange(e)}
            >
              <span className="text small">A</span>
              <span className="text medium">A</span>
              <span className="text large">A</span>
            </div>
          )}

          <div className="box-btn-wrapper">
              {showViewDraftReports && (
                <Button
                className="box-btn"
                >
                View Draft Report
                </Button>
              )}
              {showViewFinalReport && (
                <Button
                className="box-btn"
                >
                View Final Report
                </Button>
              )}
              {showDownoadDraftReport && (
                <Button
                className="box-btn"
                >
                Download Draft Report
                </Button>
              )}
              {showDownloadFinalReport && (
                <Button
                className="box-btn"
                >
                Download Final Report
                </Button>
              )}
          </div>

          <div className="text-wrapper">
              {showTextReport && (
                <>
                <span> Drafted Report </span>
                <span> Final Report </span>
                </>
              )}
          </div>

          {showActions && (
            <div className="action-btns">
              {showFilterIcon && (
                <Button
                  className="button-icon"
                  tooltip="Filter"
                  tooltipOptions={{ position: "top" }}
                  onClick={onFilterClick}
                  disabled={disabledIcon}
                >
                  <IoIosFunnel className="icon" />
                </Button>
              )}
              {showOptionsIcon && (
                <Button
                  className="button-icon"
                  tooltip="Advanced Search"
                  tooltipOptions={{ position: "top" }}
                  onClick={onOptionsClick}
                >
                  <IoMdOptions className="icon" />
                </Button>
              )}
              {showAddIcon && (
                <Button
                  className="button-icon"
                  tooltip="Add"
                  tooltipOptions={{ position: "top" }}
                  onClick={onAddClick}
                  disabled={disableAddIcon}
                >
                  <BsPlusLg className="icon" />
                </Button>
              )}
              {showEditIcon && (
                <Button
                  className="button-icon"
                  tooltip="Edit"
                  tooltipOptions={{ position: "top" }}
                  onClick={onEditClick}
                  disabled={disabledIcon}
                >
                  <BsFillPencilFill className="icon" />
                </Button>
              )}
              {showCopyIcon && (
                <Button
                  className="button-icon"
                  tooltip="Copy"
                  tooltipOptions={{ position: "top" }}
                  onClick={onCopyClick}
                  disabled={disabledIcon}
                >
                  <IoIosCopy className="icon" />
                </Button>
              )}
              {showDeleteIcon && (
                <Button
                  className="button-icon"
                  tooltip="Delete"
                  tooltipOptions={{ position: "top" }}
                  onClick={onDeleteClick}
                  disabled={disabledIcon}
                >
                  <BsTrashFill className="icon" />
                  {showDeleteDownArrow && (
                    <FaChevronDown className="p-button-text caret-icon" />
                  )}
                </Button>
              )}
              {showDetailsIcon && (
                <Button
                  className="button-icon"
                  tooltip="Inspection Details"
                  tooltipOptions={{ position: "top" }}
                  onClick={onDetailsClick}
                  disabled={disabledIcon}
                >
                  <BsGridFill className="icon" />
                </Button>
              )}
              {showCloseIcon && (
                <Button
                  className="button-icon"
                  tooltip="Close"
                  tooltipOptions={{ position: "top" }}
                  onClick={onCloseClick}
                  disabled={disabledIcon}
                >
                  <BsXCircleFill className="icon" />
                </Button>
              )}
              {showCompleteIcon && (
                <Button
                  className="button-icon"
                  tooltip="Complete"
                  tooltipOptions={{ position: "top" }}
                  onClick={onCompleteClick}
                  disabled={disabledIcon}
                >
                  <BsCheckCircleFill className="icon" />
                </Button>
              )}
              {setAsActiveSiteIcon && (
                <Button
                  className="button-icon"
                  tooltip="Set as Active Site"
                  tooltipOptions={{ position: "top" }}
                  onClick={onSetAsActiveSiteClick}
                  disabled={disabledIcon}
                >
                  <FaUserCheck className="p-button-text user-check icon" />
                </Button>
              )}
              {showHamIcon && (
                <Button
                  className="button-icon"
                  tooltip={showOrderIcon? "Report Order" :"View"}
                  tooltipOptions={{ position: "top" }}
                  onClick={onHamClick}
                  disabled={disabledIcon}
                >
                  <MdTableRows className="icon" />
                </Button>
              )}
              {showAttachIcon && (
                <Button
                  className="button-icon"
                  tooltip="Attachments"
                  tooltipOptions={{ position: "top" }}
                  onClick={onAttachmentClick}
                  disabled={disabledIcon}
                >
                  <BsPaperclip className="icon" />
                  <FaChevronDown className="p-button-text caret-icon" />
                </Button>
              )}
              {showDownloadIcon && (
                <Button
                  className="button-icon"
                  tooltip="Download"
                  tooltipOptions={{ position: "top" }}
                  onClick={onDownloadClick}
                  disabled={disabledIcon}
                >
                  <FaDownload className="icon" />
                  <FaChevronDown className="p-button-text caret-icon" />
                </Button>
              )}
              {showSettingIcon && (
                <Button
                  className="button-icon"
                  tooltip="More"
                  tooltipOptions={{ position: "top" }}
                  onClick={(e) => settingRef.current?.toggle(e)}
                  disabled={disabledIcon && !dataFromUserTable}
                >
                  <FaCog className="icon" />
                  <FaChevronDown className="p-button-text caret-icon" />
                </Button>
              )}
              {showViewIcon && (
                <Button
                  className="button-icon"
                  tooltip="View"
                  tooltipOptions={{ position: "top" }}
                  onClick={onViewClick}
                  disabled={disabledIcon}
                >
                  <FaEye className="icon" />
                </Button>
              )}
              {showDislaySettingsIcon && (
                <Button
                  className="button-icon"
                  tooltip="Dispay Settings"
                  tooltipOptions={{ position: "top" }}
                  onClick={() => setShowDisplaySetting(true)}
                  disabled={disabledIcon}
                >
                  <img alt="Display Settings" src={display_settings} className="icon"></img>
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const onRowEditComplete = (e) => {
    let currentData = [...rowData];
    let { newData, index } = e;

    onSaveRowEdit && onSaveRowEdit(newData);
  };

  const rowEditValidator = (rowData, options) => {
    let value = false;
    value = onRowEditValidation(rowData);
    return value;
  };

  const onRowEditChange = (e) => {
    if (Object.keys(editingRows).length === 0 || Object.keys(e.data).length === 0) {
      let isEditable = true;
      isEditable = onRowEditClick(rowData[e.index]);
      if (isEditable && allowRowEditing) {
        setEditingRows(e.data);
      }
      else if(isEditable && !allowRowEditing) {
        setEditingRows({})
      }
    }
  };

  const {
    control,
    setValue,
  } = useForm();

  const headerDropDownChange = useRef<any>();
  headerDropDownChange.current = onHeaderAttributeNameChange;

  useEffect(() => {
    if (attributeLookup.length > 0) {
      setValue("Attribute Name", attributeLookup[0]);
      headerDropDownChange.current(attributeLookup[0]);
    }
  }, [setValue, attributeLookup]);

  const countryDropDownChange = useRef<any>();
  countryDropDownChange.current = onCountryDropdownChange;
  useEffect(() => {
    if (countryLookup && countryLookup.length > 0 && countryLookup !== undefined)
      setValue("Country", countryLookup[0].name);
    countryDropDownChange.current(countryLookup[0]);
  }, [setValue, countryLookup])

  const systemHeaderDropdowns = (data, label) => (
    <span className="p-float-label mr-4">
      <Controller
        name={label}
        control={control}
        rules={{ required: "This field is required." }}
        render={({ field, fieldState }) => (
          <Dropdown
            inputId="dropdown"
            className={classNames("w-100 error-tooltip", {
              error: fieldState.invalid,
            })}
            {...field}
            value={field.value}
            onChange={(e) => {
              field.onChange(e.value);
              onHeaderDropdownChange(e.target);
            }}
            options={data}
            optionLabel="name"
          />
        )}
      />
      <label className="mandatory" htmlFor="dropdown">
        {label}
      </label>
    </span>
  );

  const renderHeaderDropdowns = (data, label) => (
    <span className="p-float-label mr-2">
      <Controller
        name={label}
        control={control}
        rules={{ required: "This field is required." }}
        render={({ field, fieldState }) => (
          <Dropdown
            inputId="dropdown"
            className={classNames("w-100 error-tooltip", {
              error: fieldState.invalid,
            })}
            {...field}
            value={field.value}
            onChange={(e) => {
              field.onChange(e.value);
              onHeaderDropdownChange(e.target);
              onDropdownChanges();
            }}
            options={data}
            optionLabel="name"
          />
        )}
      />
      <label className="mandatory" htmlFor="dropdown">
        {label}
      </label>
    </span>
  );

  const renderAttributeHeaderDropdowns = (data, label) => (
    <span className="p-float-label mr-4">
      <Controller
        name={label}
        control={control}
        rules={{ required: "This field is required." }}
        render={({ field, fieldState }) => (
          <Dropdown
            inputId="dropdown"
            className={classNames("w-100 error-tooltip", {
              error: fieldState.invalid,
            })}
            {...field}
            value={field.value}
            onChange={(e) => {
              field.onChange(e.value);
              onHeaderAttributeNameChange(e.target);
              onDropdownChanges();
            }}
            options={data}
            optionLabel="name"
          />
        )}
      />
      <label className="mandatory" htmlFor="dropdown">
        {label}
      </label>
    </span>
  );

  const renderCommonAttributeDropdowns = (data, label) => (
    <span className="p-float-label mr-4">
      <Controller
        name={label}
        control={control}
        rules={{ required: "This field is required." }}
        render={({ field, fieldState }) => (
          <Dropdown
            inputId="dropdown"
            className={classNames("w-100 error-tooltip", {
              error: fieldState.invalid,
            })}
            {...field}
            value={field.value}
            onChange={(e) => {
              field.onChange(e.value);
              onHeaderDropdownChange(e.target);
              onDropdownChanges();
            }}
            options={data}
            optionLabel="name"
          />
        )}
      />
      <label className="mandatory" htmlFor="dropdown">
        Attribute Name
      </label>
    </span>
  );
  const renderCountryDropdown = (data, label) => (
    <span className="p-float-label mr-4">
      <Controller
        name={label}
        control={control}
        rules={{ required: "This field is required." }}
        render={({ field, fieldState }) => (
          <Dropdown
            inputId="countryName"
            className={classNames("w-100 error-tooltip", {
              error: fieldState.invalid,
            })}
            {...field}
            value={field.value}
            onChange={(e) => {
              field.onChange(e.value);
              onCountryDropdownChange(e.target);
            }}
            options={data}
            optionLabel="name"
            optionValue="name"
          />
        )}
      />
      <label className="mandatory" htmlFor="dropdown">
        Country
      </label>
    </span>
  );

  const searchHeader = renderHeader("filters");

  useEffect(() => {
    if (defaultSelectedRows && defaultSelectedRows.length > 0) {
      setSelectedRows(defaultSelectedRows);
      getSelectedRowData(defaultSelectedRows);
    } else {
      if (rowData?.length > 0) {
        if (selectedRows.length === 0) {
          const firstRowEle = rowData[0];
          setSelectedRows([firstRowEle]);
          getSelectedRowData([firstRowEle]);
          if (firstRowEle?.isDisabled === false) {
            setSelectedRows([firstRowEle]);
            getSelectedRowData([firstRowEle]);
          }
          else if (firstRowEle?.isDisabled === true) {
            setSelectedRows([]);
            getSelectedRowData([]);
          }
        }
      } else {
        setSelectedRows([]);    //To clear previous selected row.
        getSelectedRowData([]); //To clear previous value of selected row.
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData]);

  useEffect(() => {
    if (showCountries) {
      const activeListItem = countryList.find((item) => item?.active);
      getSelectedCountry(activeListItem);
    }
  }, [countryList, showCountries, getSelectedCountry]);

  const onSelectRow = (e: any) => {
    setSelectedRows(e.value);
    getSelectedRowData(e.value);
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="expanded-row-container">
        <div className="header-section">
          <div className="info">
            <div className="box">
              <div className="title">Task Name</div>
              <div className="value">{data?.inspectionTaskName}</div>
            </div>
            <div className="box">
              <div className="title">System</div>
              <div className="value">{data?.system}</div>
            </div>
            <div className="box">
              <div className="title">Panel Type</div>
              <div className="value">{data?.panelType}</div>
            </div>
          </div>
          <div
            className="close-btn"
            role="button"
            onClick={() => setExpandedRows({})}
          >
            <i className="pi pi-times"></i>
          </div>
        </div>

        <div className="main-section">
          <div className="knob-wrapper">
            <div className="knob-value-wrapper">
              <div className="knob-value">
                <span className="text">Passed</span>
                <span className="value">1050</span>
              </div>
            </div>
            <div className="knob">
              <div className="mask half">
                <div className="fill" />
              </div>
              <div className="mask full">
                <div className="fill" />
              </div>
            </div>
          </div>

          <div className="table-card tests-equipments">
            <div className="border yellow-border" />
            <div className="content">
              <div className="row h-50">
                <div className="col-6">
                  <div className="title">Test Passed</div>
                  <div className="value success">1050</div>
                </div>
                <div className="col-6">
                  <div className="title">Total Equipments</div>
                  <div className="value error">1200</div>
                </div>
              </div>
              <div className="row h-50">
                <div className="col-6">
                  <div className="title">Test Failed</div>
                  <div className="value warning">50</div>
                </div>
                <div className="col-6">
                  <div className="title">Tested Equipments</div>
                  <div className="value error">1100</div>
                </div>
              </div>
            </div>
          </div>

          <div className="table-card tech-plan">
            <div className="border green-border" />
            <div className="content">
              <div className="row h-50">
                <div className="col-6">
                  <div className="title">Lead Technician</div>
                  <div className="value success">Tom, Brad</div>
                </div>
                <div className="col-6">
                  <div className="title">Planned Start Date</div>
                  <div className="value error">12 Feb 2020</div>
                </div>
              </div>
              <div className="row h-50">
                <div className="col-6">
                  <div className="title">Tech Required</div>
                  <div className="value warning">Yes (2)</div>
                </div>
                <div className="col-6">
                  <div className="title">Planned End Date</div>
                  <div className="value error">12 feb 2021</div>
                </div>
              </div>
            </div>
          </div>

          <div className="table-card user-actions">
            <div className="content">
              <div className="row h-50">
                <div className="col-6 d-flex align-items-center">
                  <BsXCircle />
                  <div className="icon-title">Close Task</div>
                </div>
                <div className="col-6 d-flex align-items-center">
                  <BsCheckCircle />
                  <div className="icon-title">Complete</div>
                </div>
              </div>
              <div className="row h-50">
                <div className="col-6 d-flex align-items-center">
                  <BsBoxArrowRight />
                  <div className="icon-title">Quit Task</div>
                </div>
                <div className="col-6 d-flex align-items-center">
                  <IoIosRemoveCircleOutline />
                  <div className="icon-title">Inactivate</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleRowToggle = (e) => {
    if (showExpandedRows) {
      const selectedRowItem = e?.data;
      const selectedRowIndex = selectedRowItem[dataKeyId];
      const expandedRowId = Object.keys(expandedRows || {});
      const isRowCollapseCall =
        parseInt(selectedRowIndex) === parseInt(expandedRowId[0]);

      const modifiedExpandedRows = {};

      if (selectedRowIndex && !isRowCollapseCall) {
        modifiedExpandedRows[`${selectedRowIndex}`] = true;
      }

      setExpandedRows(modifiedExpandedRows);
    }
  };

  const isRowSelectable = (event) => {
    if(event?.data?.isDisabled&&event?.data?.isDisabled===true){
      return false;
    }else{
      return true;
    }
  }

  const bodyTemplate = (rowData, columnDetails)=>{
    if (isTemplateMode) {
      return (<div dangerouslySetInnerHTML={{__html: `${rowData[columnDetails?.field]}` }} />);
    } else {
      return (<div>{rowData[columnDetails?.field]}</div>)
    }
  }

  return (
    <>
      <div className="custom-table">
        {showCustomHeader ? (
          <CustomHeaderCmp />
        ) : (
          <div className="heading">{title}</div>
        )}
        <DataTable
          value={rowData}
          size="small"
          responsiveLayout="scroll"
          editMode="row"
          editingRows={editingRows}
          onRowEditComplete={onRowEditComplete}
          rowEditValidator={rowEditValidator}
          onRowEditChange={onRowEditChange}
          paginator={paginator}
          paginatorTemplate={paginatorTemplate}
          first={firstPage}
          rows={totalRows}
          onPage={onCustomPage}
          onSort={onSort}
          sortField={sortField}
          sortOrder={sortOrder}
          selectionMode="multiple"
          dragSelection
          selection={selectedRows}
          onSelectionChange={(e) => onSelectRow(e)}
          dataKey={dataKeyId}
          emptyMessage="No data found."
          header={searchHeader}
          filters={filters}
          onFilter={(e) => setFilters(e.filters as any)}
          scrollable
          scrollDirection="both"
          //resizableColumns
          columnResizeMode="expand"
          showGridlines={false}
          expandedRows={expandedRows}
          onRowDoubleClick={handleRowToggle}
          rowExpansionTemplate={rowExpansionTemplate}
          totalRecords={totalRecords}
          lazy={isLazyLoading}
          isDataSelectable={isRowSelectable}
          onValueChange={onRowDataChanges}
        >
          {showroweditor ? (
            <Column
              rowEditor
              exportable={false}
              alignFrozen="left"
              frozen
            ></Column>
          ) : null}

          {showCheckbox ? (<Column selectionMode="multiple" headerStyle={{ }} exportable={false}></Column> ) : null}

          {tableColumns.map(({ field, header, editor, style, sortable, headerClassName, align, excludeGlobalFilter }, index) => {
            return (
              <Column
                key={field}
                field={field}
                header={header}
                editor={editor}
                style={style}
                sortable={sortable}
                align={align}
                headerClassName={headerClassName}
                body={isTemplateFromParent ? templateFromParent : bodyTemplate}
                excludeGlobalFilter={excludeGlobalFilter}
              />
            );
          })}
        </DataTable>
      </div>

      <PopOver ref={settingRef}>
        <ul>
          {settingListItems?.length > 0 &&
            settingListItems.map((item: SettingListItem) => (
              <li key={item?.id} role="button" onClick={item?.onClick}>
                {item?.name}
              </li>
            ))}
            
          <li role="button" onClick={() => setShowDisplaySetting(true)}>
            Display Settings
          </li>
        </ul>
      </PopOver>
      {showDisplaySetting && (<DisplaySetting
        showModal={showDisplaySetting}
        setShowModal={setShowDisplaySetting}
        displayColumns={cols}
        setdisplayColumns={setTableColumns}
        selectedCols={tableColumns}
      />)}
    </>
  );
};

export default TableComponent;
